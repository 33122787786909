.contact-form-title {
    text-transform: capitalize;
    font-size: 32px;
    font-family: 'gotham-light';
    margin-bottom: 26px;
    color: #373737;
}

.input-last {
    margin-top: 50px;
}

.contact-form {
    display: flex;
}

.send-button {
    text-transform: uppercase;
    border: none;
    color: #5A5959;
    background-color: #FFFFFF;
    font-family: 'gotham-light';
    font-size: 20px;
    display: flex;
    justify-content: flex-end;
    outline: none;
}

.send-button:active {
    outline: none;
}

.contact-form-container {
    display: flex;
    justify-content: center;
}

.form-input {
    font-family: 'gotham-light';
    font-size: 15px;
    display: inline;
    border: none;
    border-bottom: 1px solid #37373767;
    padding-top: 2px;
    width: 427px;
    outline: none;
    margin-bottom: 40px;
    padding-bottom: 10px;
}

.contact-form-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.checkbox-item {
    position: relative;
    height: 180px;
    width: 180px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-right: 25px;
    margin-bottom: 25px;
    font-size: 12px;
    font-family: 'gotham-light';
    text-transform: uppercase;
}

.brand-checkbox,
.events-checkbox,
.packaging-checkbox {
    background-color: #EFEFEF;
    border: 0.5px solid #AFAFAF;
    border-radius: 3px;
    color: #5A5959;
}

.communication-checkbox {
    border: 0.5px solid #AFAFAF;
    border-radius: 3px;
    color: #5A5959;
    background: #E2E9DC 0% 0% no-repeat padding-box;
}

.digital-checkbox {
    background: #C3D7A0 0% 0% no-repeat padding-box;
    color: #5A5959;
    border-radius: 3px;
    opacity: 1;
}

.product-checkbox {
    background: #7F9A5A 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
    color: #FFFFFF;
}

.checkbox-wrap {
    display: flex;
}

.check-box {
    position: absolute;
    top: 0;
    right: 0;
}


.checkbox-container {
    margin-left: 70px;
    padding-top: 30px;
}

.icons {
    font-size: 30px;
    color: #444444;
}

.mobile-contact-form-wrap {
    margin-top: 60px;
    margin-bottom: 20px;
    background-color: #fff;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    z-index: 120;
}

.mobile-send-button {
    letter-spacing: 2.3px;
    text-align: left;
    color: #606060;
    opacity: 1;
    border: none;
    background: #ffff;
    font-size: 14px;
    font-family: 'roboto';
    text-transform: uppercase;
    margin-top: 30px;
}

.mobile-contact-form-input {
    border: none;
    border-bottom: 1px solid #D6D6D6;
    text-transform: uppercase;
    background-color: #fff;
    height: 40px;
    padding: 10px 0;
    letter-spacing: 2.24px;
    font-size: 14px;
    opacity: 1;
}

.mob-cont-title {
    margin-bottom: 10px;
    text-align: left;
    font: normal normal 300 25px Lato;
    letter-spacing: 0.5px;
    color: #8AA86E;
    opacity: 1;
    padding: 10px 0 0 0;
    transition: ease-in-out 5s;
}

.mobile-contact-detail-img {
    width: 100%;
    height: 100%;
}

.let-us-know {
    height: 25px;
}


/* -----------------------------MEDIA QUERIES------------------------ */
@media (max-width:1024px) {
    .form-input {
        width: 350px;
        margin-bottom: 30px;
    }

    .checkbox-item {
        height: 150px;
        width: 150px;
    }
}

@media(max-width:800px) {
    .contact-form-title {
        text-align: center;
    }

    .form-input {
        width: 290px;
    }

    .checkbox-container {
        margin-left: 20px;
    }

    .checkbox-item {
        height: 120px;
        width: 120px;
    }

    .form-input {
        margin-bottom: 15px;
    }
}

@media (max-width:425px) {
    .checkbox-wrap {
        justify-content: space-between;
        padding: 10px 0;
    }

    .checkbox-item {
        margin: 0;
        font-size: 9px;
        height: 95px;
        width: 95px;
    }

    .mobile-send-button {
        margin-top: 10px;
    }
}

@media(max-width:375px) {
    .mobile-contact-form-wrap {
        padding: 0 20px;
    }
}

@media(max-width:320px) {
    .checkbox-item {
        height: 90px;
        width: 90px;
        margin: -2px;
    }

    .checkbox-wrap {
        padding: 10px 0 0 0;
    }
}