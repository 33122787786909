.project-image {
    width: 100%;
    height: 100%;
}

.homescreen-project-container {
    display: flex;
    justify-content: space-between;
    margin: 0;
    width: 100%;
}

.homescreen-project-container-one {
    width: 50%;
}

.homescreen-project-container-two {
    width: 50%;
    justify-content: center;
    flex-direction: column;
    display: flex;

}

.homescreen-project-container-one {
    padding: 0 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.homescreen-project-grid {
    height: 150px;
    width: 150px;
}

.homescreen-project-text {
    font-family: 'roboto-light';
    font-size: 16px;
    letter-spacing: 1.12px;
    color: #646464;
    width: 454px;
    height: 137px;
}

.homescreen-project-grid-two {
    background-color: #FFFFFF;
}

.homescreen-project-wrap {
    display: flex;
}

.mobile-home-project-carousel {
    width: 100%;
    height: 340px;
}

/* ------------------------------MEDIA QUERIES------------------------------- */
@media (max-width: 1024px) {
    .project-image {
        width: 100%;
        height: 638px;
    }

    .homescreen-project-container-one {
        padding: 0 35PX;
    }
}

@media(max-width:800px) {
    .homescreen-about-grid {
        width: 150px;
        height: 150px;
    }

    .homescreen-about-text,
    .homescreen-about-title {
        width: 350px;
    }

    .homescreen-project-text {
        width: auto;
    }

    .project-image {
        width: 100%;
        height: 500px;
    }

    .homescreen-project-container-one {
        display: flex;
        margin-top: 75px;
        flex-direction: column;
        margin-bottom: 0;
        justify-content: center;
        height: auto;
    }

    .homescreen-project-text {
        height: auto;
    }
}

@media(max-width:768px) {
    .homescreen-project-container {
        margin: 0;
        padding-left: 15px;
    }

    .homescreen-project-grid {
        width: 110px;
        height: 110px;
    }

    .homescreen-project-text {
        padding: 0 10px;
        font-size: 14px;
    }
}

@media (min-width:576px) {
    .mobile-about-container {
        display: none;
    }
}

@media (max-width:425px) {

    .homescreen-project-container {
        display: none;
    }
}