.menu-designs-col-one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 130px;
}

.menu-grid-image {
    width: 130px;
    height: 130px;
}

.menu-designs-grid {
    display: flex;
}

.menu-designs-description {
    color: #606060;
    font-size: 14px;
    font-family: 'roboto-light';
    padding-right: 130px;
}

.menu-designs-link-wrap {
    display: flex;
    text-transform: uppercase;
}

.menu-sublink-divider {
    border-right: 1px solid #8AA86E;
}

.menu-designs-sublink {
    color: #7F7F7F;
    letter-spacing: 1.95px;
    font-family: 'roboto-medium';
    font-size: 15px;
}

.menu-designs-container {
    margin-top: 150px;
    justify-content: space-between;
    display: flex;
}

.menu-designs-wrap {
    width: 683px;
    height: 768px;
}

.menu-designs-hero-image {
    width: 100%;
    height: 100%;
}

.menu-designs-link-title {
    color: #8AA86E;
    letter-spacing: 1.4px;
    color: #8AA86E;
    font-size: 20px;
    font-family: 'roboto-medium';
    text-transform: uppercase;
    opacity: 1;
}

.menu-designs-left-arrow {
    margin-left: -65px;
}

.menu-designs-carousel-image {
    width: 100%;
    height: 100%;
}

.menu-designs-grid-carousel {
    display: flex;
}

.menu-designs-grid-image-link {
    width: 227px;
    height: 227px;
}

.menu-design-link-hero-image {
    width: 100%;
    height: 100%;
}

.mobile-photoshoot-title {
    font: normal normal normal 20px/24px Roboto;
    letter-spacing: 1.44px;
    color: #929292;
    opacity: 1;
    padding: 10px 0 10px 20px;
    text-transform: capitalize;
}

.mobile-menu-designs-grid-image-link {
    width: 100%;
    height: 100%;
    padding: 1px;
}

.mobile-designs-row {
    display: flex;
    justify-content: space-between;
    flex-basis: 50%;
}

@media (max-width:1440px) {
    .menu-designs-container {
        margin-top: 0;
    }
}

@media(max-width: 1200px) {
    .menu-designs-grid-image-link {
        width: 200px;
        height: 215px;
    }

    .menu-designs-wrap {
        height: 653px;
        width: 600px;
    }

    .menu-designs-col-one {
        justify-content: flex-end;
        padding-bottom: 25px;
    }

    .menu-designs-carousel-image {
        height: 650px;
    }
}

@media (max-width:1024px) {
    .menu-designs-wrap {
        width: 512px;
        height: 600px;
    }

    .menu-designs-col-one {
        padding-bottom: 15px;
        padding-left: 100px;
    }

    .menu-grid-image {
        width: 110px;
        height: 110px;
    }

    .menu-designs-carousel-image {
        height: 100%;
    }

    .menu-designs-grid-image-link {
        width: 170px;
        height: 200px;
    }

    .menu-designs-left-arrow {
        margin-left: -40px;
    }

    .arrow-left {
        margin-right: 15px;
    }

}

@media (max-width:768px) {
    .menu-designs-wrap {
        width: 378px;
        height: 510px;
    }

    .menu-designs-col-one {
        padding-bottom: 15px;
        padding-left: 50px;
    }

    .menu-grid-image {
        width: 110px;
        height: 110px;
    }

    .menu-designs-carousel-image {
        height: 510px;
    }

    .menu-designs-grid-image-link {
        width: 145px;
        height: 170px;
    }

    .menu-designs-left-arrow {
        margin-left: -40px;
    }

    .arrow-left {
        margin-right: 15px;
    }

    .menu-designs-description {
        padding-right: 50px;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .menu-designs-sublink {
        font-size: 13px;
    }

    .menu-designs-col-two {
        width: 435px;
    }

}

@media (max-width:767px) {
    .menu-designs-container {
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media (max-width:575px) {
    .menu-designs-container {
        flex-wrap: wrap;
    }

    .menu-designs-col-one {
        padding-left: 30px;
    }

    .menu-designs-left-arrow {
        margin-left: 0;
    }
}

@media (max-width:425px) {

    .desktop-design-img,
    .desktop-design-carousel,
    .web-video-design {
        display: none;
    }


    .menu-designs-col-one {
        height: 100%;
        width: 100%;
        margin-top: 100px;
    }

    .menu-designs-col-two {
        height: 100%;
        width: 100%;
    }

    .menu-designs-carousel-image {
        height: 100%;
        width: 100%;
    }
}

@media (min-width:426px) {

    .mobile-design-carousel,
    .mobile-design-photoshoot,
    .mobile-video-design {
        display: none;
    }
}