* {
  box-sizing: border-box;
  padding: 0;
  width: auto;
  margin: 0;
  max-width: 1440px;
}

.Container,
.container {
  margin: 0 auto;
  padding-left: 0px !important;
  padding-right: 0px !important;
  max-width: 1440px;
}

.page-container {
  height: 100vh;
}

a {
  color: #7F7F7F;
  text-decoration: none;
}

a:hover {
  color: #BBBBBB;
  text-decoration: none;
}

html,
body {
  color: #5A5959;
  font-weight: normal;
  font-size: 16px;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  margin: 0 auto;
}

/* Font Imports */
@font-face {
  font-family: 'timewon';
  src: url('./assets/fonts/timewon.ttf') format('truetype'),
    url('./assets/fonts/timewon.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto-light';
  src: url('./assets/fonts/roboto_light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto-medium';
  src: url('./assets/fonts/roboto_medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gotham-light';
  src: url('./assets/fonts/GothamLight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gotham';
  src: url('./assets/fonts/GothamBook.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body::-webkit-scrollbar {
  -webkit-appearance: none;
}