.hero-image-container {
    display: flex;
    justify-content: center;
}

.hero-image-slide img {
    width: 683px;
    height: 768px;
}

.slide-two {
    padding-top: 1.2px;
}

.services-grid-container {
    margin-top: 150px;
    margin-left: 114px;
}

.homescreen-services-block {
    display: flex;
    justify-content: center;
}

.decor-image {
    height: 500px;
    margin-right: -20px;
    margin-top: 43px;
}

.pencil-image {
    width: 68px;
    height: 572px;
    margin-top: 130px;
}

.services-grid-image img {
    height: 180px;
    width: 180px;
    margin-right: 30px;
}

.services-grid-wrap {
    display: flex;
}

.mobile-hero-img {
    width: 95%;
    margin-top: 75px;
}

.mobile-services-grid {
    height: 100px;
    width: 100px;
}

.mobile-decor {
    width: 35vw;
    padding-right: 5px;
    height: 70vh;
}

.mobile-services-block {
    display: flex;
    justify-content: flex-end;
}

.mob-service-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.demo-page-contain {
    height: 100vh;
}

/* --------------------------------MEDIA QUERIES------------------------------- */
@media (max-width:1024px) {
    .hero-image-slide img {
        margin-top: 50px;
        width: 583px;
        height: 668px;
    }

    .pencil-image {
        margin-right: -50px;
    }

    .decor-image {
        margin-left: -20px;
    }
}

@media (max-width:992px) {
    .hero-image-slide img {
        width: 533px;
        height: 618px;
    }

    .services-grid-image img {
        width: 170px;
        height: 170px;
    }

    .decor-image {
        height: 479px;
    }

    .pencil-image {
        height: 517px;
    }
}

@media (max-width: 800px) {
    .hero-image-slide img {
        width: 463px;
        height: 548px;
    }

    .services-grid-image img {
        width: 150px;
        height: 150px;
        margin-right: 20px;
    }

    .pencil-image {
        margin-right: -70px;
    }

    .decor-image {
        margin-top: 0;
        padding-top: 30px;
    }

    .homescreen-services-block {
        margin-top: 100px;
    }
}

@media (max-width:768px) {
    .services-grid-container {
        margin-left: 90px;
    }

    .hero-image-slide img {
        width: 100%;
        height: 100%;
    }

    .social-media-icons {
        font-size: 45px;
        width: 90px;
        height: 90px;
    }
}

@media (max-width:767px) {
    .decor-image {
        display: none;
    }

    .services-grid-image img {
        margin-right: 0;
    }

    .services-grid-image img {
        width: 100%;
        height: 100%;
        padding: 10px;
        margin-right: 0px !important;
    }
}

@media(max-width:425px) {

    .hero-image-container,
    .homescreen-services-block,
    .homescreen-about-container,
    .homescreen-project-container,
    .social-media-icons-container,
    .contact-form-container,
    .footer-container,
    .footer-tagline,
    .web-about-us,
    .web-project-container {
        display: none !important;
    }
}

@media(min-width:426px) {

    .mobile-hero-image,
    .mobile-services-block,
    .mobile-about-container,
    .mobile-contact-form,
    .mobile-social-media-icon,
    .mobile-footer,
    .mobile-about-us,
    .mobile-project-wrap {
        display: none !important;
    }
}