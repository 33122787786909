.web-pc-work-wrap {
    width: 100%;
    height: 580px;
}

.carousel-arrow-left {
    font-size: 24px;
    margin-right: 20px;
}

.carousel-title {
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 35px;
}

.mobile-pc-work-carousel {
    margin-top: 70px;
}

.Invite-video-control {
    height: 10cm;
    width: 100%;
}

.invites-video-title {
    padding: 20px;
    font: normal normal normal 20px/24px Roboto;
    letter-spacing: 1.44px;
    color: #929292;
    opacity: 1;
}

.web-pc-work-carousel {
    margin-top: 100px;
}

.mobile-pc-work-wrap {
    width: 100%;
    height: 10cm;
}

@media (max-width:1366px) {}

@media(max-width:575px) {
    .web-pc-work-carousel {
        display: none;
    }
}

@media(min-width:575px) {
    .mobile-pc-work-carousel {
        display: none;
    }


}

@media (max-width:425px) {
    .carousel-title {
        padding-left: 10px;
    }

    .web-pc-work-wrap {
        height: 10cm;
    }
}