.mobile-project-container {
    padding: 50px 0px;
    background-color: #ECECEC;
}

.mob-project-img {
    width: 100%;
    padding: 4px;
}

.mobile-project-wrap {
    margin-top: 100px;
}