.menu-designs-col-one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 130px;
}

.menu-grid-image {
    width: 130px;
    height: 130px;
}

.menu-designs-grid {
    display: flex;
}

.menu-designs-description {
    color: #606060;
    font-size: 14px;
    font-family: 'roboto-light';
    padding-right: 130px;
}

.menu-designs-link-wrap {
    display: flex;
    text-transform: uppercase;
}

.menu-sublink-divider {
    border-right: 1px solid #8AA86E;
}

.menu-designs-sublink {
    color: #7F7F7F;
    letter-spacing: 1.95px;
    font-family: 'roboto-medium';
    font-size: 15px;
}

.menu-designs-container {
    margin-top: 150px;
    display: flex;
}

.menu-designs-wrap {
    width: 683px;
    height: 768px;
}

.menu-designs-hero-image {
    width: 100%;
    height: 100%;
}

.menu-designs-link-title {
    color: #8AA86E;
    letter-spacing: 1.4px;
    color: #8AA86E;
    font-size: 20px;
    font-family: 'roboto-medium';
    text-transform: uppercase;
    opacity: 1;
}

.menu-designs-left-arrow {
    margin-left: -65px;
}

.menu-designs-carousel-image {
    width: 100%;
    height: 100%;
}

.menu-designs-grid-carousel {
    display: flex;
}

.menu-designs-grid-image-link {
    width: 227px;
    height: 227px;
}

.menu-design-link-hero-image {
    width: 100%;
    height: 100%;
}

@media (max-width:1366px) {
    .menu-designs-container {
        margin-top: 0;
    }
}