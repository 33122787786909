.social-media-title {
    color: #5A5959;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'gotham';
    margin-bottom: 50px;
}


.social-media-icons {
    width: 140px;
    height: 140px;
    border-radius: 3px;
    opacity: 1;
    color: #FFFFFF;
    font-size: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 4px;
}

.social-media-container {
    display: flex;
    justify-content: space-around;
}

.social-media-icons-wrap {
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.facebook {
    background-color: #7F9A5A;
}

.instagram {
    background-color: #677F40;
}

.twitter {
    background-color: #AFCE94;
}

.pinterest {
    background-color: #7F9A5A;
}

.linkedin {
    background-color: #AFCE94;
}

.whatsapp {
    background-color: #C8DDAF;
}

.youtube {
    background-color: #71874D;
}

.mob-social-icon {
    color: #ffffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50px;
    width: 100%;
    font-size: 35px;
}

.mobile-social-title {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2.24px;
    color: #606060;
    opacity: 1;
    font-size: 14px;
    font-family: 'roboto';
    margin-top: 50px;
    margin-bottom: 15px;
}

/* ------------------------------MEDIA  QUERIES------------------- */
@media (max-width:1024px) {
    .social-media-icons {
        width: 130px;
        height: 130px;
    }


}

@media(max-width:800px) {
    .social-media-icons {
        width: 100px;
        height: 100px;
    }
}

@media(max-width:425px) {

    .abc,
    .social-icon {
        display: none !important;
    }
}

@media (min-width:426px) {

    .mobile-contact-detail-img,
    .pg {
        display: none !important;
    }
}