.about-us-title {
    color: #8AA86E;
    font: normal normal 100 38px/20px Lato;
    letter-spacing: 0.76px;
    color: #8AA86E;
    margin-top: -25px;
    padding-bottom: 40px;
}

.about-us-text {
    font-family: 'roboto-light';
    letter-spacing: 1.36px;
    font-size: 16px;
    color: #444444;
    font-weight: 300;
    padding-right: 150px;
}

.arrow-left {
    height: 25px;
    width: 25px;
    background-color: #EFEFEF;
    color: #747474;
    text-align: center;
    margin-right: 40px;
    margin-bottom: 5px;
}

.about-us-content,
.about-us-carousel {
    width: 683px;
    height: 768px;
}

.about-us-container {
    margin-top: 150px;
}

.about-us-wrap {
    display: flex;
    justify-content: space-between;
}

.carousel-control-prev,
.carousel-control-next {
    display: none;
}

.carousel-indicators .active,
.carousel-indicators li {
    width: .625rem;
    height: .625rem;
    border-radius: 50%;
}

.carousel-container {
    width: 633px;
    height: 766px;
}

.about-us-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.carousel-image {
    width: 100%;
    height: 100%;
}

.vision-wrap {
    display: flex;
}

.team-container {
    background-color: #F1F2F2;
    width: 100%;
    height: 768px;
    padding-left: 200px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.team-content {
    display: flex;
}

.team-text-content {
    width: 350px;
}

.team-text {
    display: flex;
}

.premium-container {
    height: 768px;
    width: 100%;
    background-color: #787777;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.premium-wrap {
    flex-direction: column;
}

.premium-right-arrow {
    margin-right: 15px !important;
    margin-bottom: 8px;
    color: white;
}

.premium-title {
    color: #FFFFFF;
    opacity: 1;
    font: normal normal 100 38px/45px Lato;
    letter-spacing: 0.76px;
    width: 147px;
    display: flex;
}

.premium-client-logo {
    width: 200px;
    height: 80px;
    margin-right: 15px;
    margin-top: 20px;
}

.premium-clients-wrap {
    display: flex;
}

.premium-wrap {
    display: inline-grid;
    justify-content: center;
}

.preciso-group-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 150px;
}

.preciso-group-container {
    display: flex;
    justify-content: space-between;
}

.preciso-concepts-part {
    font-family: 'roboto-light';
    font-size: 25px;
    letter-spacing: 0.5px;
    color: #444444;
}

.preciso-wrap {
    height: 786px;
    width: 683px;
}

.preciso-group-title {
    font: normal normal 100 60px/32px Lato;
    letter-spacing: 1.2px;
    color: #8AA86E;
    margin-left: -5px;
}

.preciso-group-explore {
    font: normal normal 300 18px/31px Roboto;
    letter-spacing: 2.34px;
    color: #444444;
    opacity: 0.4;
}

.prciso-group-links {
    font: normal normal normal 22px/32px Roboto;
    letter-spacing: 0.44px;
    color: #444444;
    opacity: 1;
}

img.preciso-group-image {
    width: 100%;
    height: 100%;
}

.mobile-about-title {
    text-transform: uppercase;
    color: #8AA86E;
    font-family: 'roboto-medium';
    font-size: 20px;
    letter-spacing: 1.4px;
    background-color: #fff;
    height: 40px;
}

.mob-left-arrow {
    margin-left: 25px;
    width: 30px;
    height: 30px;
}

.mob-about-text {
    letter-spacing: 1.36px;
    padding: 25px;
    color: #FFFFFF;
    opacity: 1;
    font-family: 'roboto';
    background-color: rgba(0, 0, 0, 0.33);
    font-size: 16px;
}

.mobile-about-wrap {
    background-image: url(./s1.png);
    background-attachment: fixed;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 760px;
}

.mobile-about-us {
    margin-top: 100px;
}

.mob-vision-img {
    width: 100%;
    height: 100%;
}

.mob-vision-title {
    font-size: 20px;
    font-family: 'roboto';
    color: #8AA86E;
    text-transform: uppercase;
}

.mob-vision-text {
    font: normal normal 300 16px/19px Roboto;
    letter-spacing: 1.36px;
    color: #444444;
    opacity: 1;
}

.mobile-vision {
    padding: 90px 20px 35px 25px;
}

.mob-grp-title {
    font: normal normal 100 38px/20px Lato;
    letter-spacing: 0.76px;
    color: #8AA86E;
    opacity: 1;
}

.mob-grp-explore {
    font: normal normal 300 18px Roboto;
    letter-spacing: 2.34px;
    color: #444444;
    opacity: 0.4;
    text-transform: uppercase;
}

.mob-grp-link {
    font: normal normal normal 22px Roboto;
    letter-spacing: 0.44px;
    color: #444444;
    opacity: 1;
}



.mob-grp {
    padding: 150px 25px;
}

/* <-------------MEDIA QUERIES--------------> */
@media (max-width:1440px) {

    .about-us-container {
        margin-top: 0;
    }


    .about-us-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 100px;
    }
}

@media (min-width:1367px) {
    .about-us-container {
        width: 100%;
    }

    .about-us-content,
    .about-us-carousel {
        width: 50%;
        height: 100%;
    }

    .about-us-content {
        padding-top: 320px;
    }
}

@media(max-width:1200px) {
    .about-us-content {
        padding-left: 30px;
    }

    .about-us-content,
    .about-us-carousel {
        height: 674px;
        width: 605px;
    }

    .team-container {
        padding-left: 100px;
    }

    .premium-container {
        width: auto;
    }

    .preciso-group-wrap {
        padding-left: 75px;
    }
}

@media(max-width:1024px) {
    .abt-top {
        padding-top: 150px;
    }

    .about-us-text {
        padding-right: 20px;
    }

    .about-us-content,
    .about-us-carousel {
        height: 575px;
        width: 524px;
    }

    .team-container {
        padding-left: 25px;
    }

    .team-container {
        height: 575px;
    }

    .premium-container {
        height: 575px;
    }

    .preciso-wrap {
        height: 575px;
    }
}

@media(max-width:992px) {

    .about-us-content,
    .about-us-carousel {
        height: 558px;
        width: 502px;
    }

    .preciso-group-title {
        font-size: 50px;
    }
}

@media (max-width:800px) {
    .abt-cont {
        display: none;
    }

    .about-us-content,
    .about-us-carousel {
        height: 449px;
        width: 410px;
    }

    .about-us-title {
        margin-bottom: 25px;
    }

    .about-us-text {
        margin-bottom: -50px;
    }

    img.team-content-image {
        width: 415px;
    }

    .about-us-title {
        margin-bottom: 0;
    }

    .team-container {
        height: 449px;
    }

    .premium-clients-container {
        margin-top: 0 !important;
    }

    .premium-client-logo {
        width: 165px;
        height: 60px;
    }

    .premium-container {
        height: 449px;
    }

    .preciso-wrap {
        height: 449px;
    }

    .social-media-icons-container {
        margin: 250px 0;
    }

    .about-vision-text {
        margin-left: 0 !important;
    }

}

@media(max-width:768px) {
    .about-us-text {
        margin-bottom: -100px;
    }

    @media (max-width: 800px) {
        .abt-top {
            padding-top: 100px;
        }


        .about-us-content,
        .about-us-carousel {
            height: 431px;
            width: 395px;
        }
    }

    .prciso-group-links {
        display: flex;
        flex-direction: column;
    }

}

@media (max-width:425px) {
    .arrow-left {
        margin-right: 15px;
    }
}