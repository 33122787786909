.footer-tagline {
    font-size: 11px;
    font-family: 'roboto-light';
    text-align: left;
    text-transform: capitalize;
    width: 100vw;
}

.footer-wrap {
    display: flex;
}

.footer-grid-container {
    margin-left: 50px;
}

.footer-grid {
    width: 196px;
    height: 196px;
    background: #EFEFEF 0% 0% no-repeat padding-box;
    border: 1px solid #E2E9DC;
    border-radius: 3px;
    opacity: 1;
    padding: 35px 0 0 30px;
    margin: 0 15px 15px 0;
}

.footer-grid-title {
    font-size: 25px;
    font-family: 'gotham-light';
    color: #5A5959;
    text-transform: uppercase;
}

.footer-item {
    list-style: none;
    font-family: 'gotham-light';
    color: #5A5959;
    font-size: 11px;
    text-transform: capitalize;
}

.footer-container {
    display: flex;
    justify-content: center;
}

.footer-image-block img {
    height: 429px;
}

.mobile-footer {
    background-color: #8C8C8C;

}

.mobile-footer a {
    color: #fff;
}

.mobile-footer a:hover {
    color: rgba(255, 255, 255, 0.486);
}

.mobile-footer-wrap {
    line-height: 1.3;
    display: flex;
    padding: 20px 15px;
    justify-content: space-between;
    text-transform: uppercase;
    font-family: 'roboto-light';
    font-size: 14px;
    color: #FFFFFF;
    opacity: 0.7;
}

.ft-img {
    width: 100%;
    height: 100%;
}

.g {
    margin-top: 60px;
}

.footer-image {
    margin-top: -60px;
    height: 328px;
    width: 440px;
}


.footer-two {
    margin-top: 150px;
}

.footer-row {
    display: flex;
    justify-content: center;
    height: 100px;
}

.footer-row-two {
    padding-left: 5px;
    width: 30%;
}

.footer-contact {
    padding-left: 45px;
}

.address-title {
    margin: 25px 0 10px 0;
}

@media (max-width:1024px) {
    .footer-row-two {
        width: 42%;
    }
}

@media(max-width:800px) {
    .footer-container {
        flex-wrap: wrap-reverse;
    }

    .footer-row {
        padding: 0 10px;
    }

    .footer-row-two {
        width: 49%;
    }
}

@media (max-width:425px) {

    .web-footer {
        display: none !important;
    }
}

@media(max-width:375px) {
    .mobile-footer-wrap {
        font-size: 12px;
    }
}