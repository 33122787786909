.brand-design-wrap,
.brand-design-carousel-image {
    width: 683px;
    height: 768px;
}

.brand-design-col-one {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.brand-design-container {
    display: flex;
    justify-content: space-between;
    margin-top: 150px;
}

.brand-design-menu {
    width: 130px;
    height: 130px;
    border: 1px solid #CFD0CF;
    border-radius: 6px;
    opacity: 1;
}

.brand-design-page-grid {
    display: flex;
}

.brand-design-description {
    font-family: 'roboto-light';
    font-size: 14px;
    letter-spacing: 1.19px;
    color: #606060;
    padding-right: 100px;
}


.brand-design-menu-wrap {
    display: flex;
    font-family: 'roboto-medium';
    letter-spacing: 1.95px;
    color: #7F7F7F;
    opacity: 1;
}

.brand-design-col-one {
    padding-left: 150px;
}

.brand-design-menu-title {
    color: #8AA86E;
    font-size: 20px;
    text-transform: uppercase;
}

.brand-design-arrow {
    margin-left: -65px;
}

@media (max-width:1440px) {
    .brand-design-container {
        margin-top: 0;
    }
}

@media (max-width:1200px) {

    .brand-design-wrap,
    .brand-design-carousel-image {
        width: 583px;
        height: 668px;
    }

    .brand-design-menu-image {
        width: 100%;
        height: 100%;
    }
}

@media (min-width:1025px) {
    .brand-design-col-one {
        padding-top: 150px;
    }
}

@media (max-width: 1024px) {
    .brand-design-arrow {
        margin-left: -40px;
    }


    .brand-design-wrap,
    .brand-design-carousel-image {
        width: 483px;
        height: 568px;
    }

    .brand-design-col-one {
        justify-content: flex-end;
        padding-bottom: 15px;
    }

    .brand-design-description {
        padding-top: 5px !important;
    }

    .brand-design-menu-wrap {
        margin-top: 0px !important;
        padding-bottom: 15px;
    }

}

@media(max-width:800px) {

    .brand-design-wrap,
    .brand-design-carousel-image {
        width: 403px;
        height: 488px;
    }

    .brand-design-col-one {
        padding-left: 40px;
    }

    .brand-design-menu-wrap {
        font-size: 13px;
    }

    .brand-design-menu {
        height: 100px;
        width: 100px;
    }

    .brand-design-description {
        font-size: 12px;
        padding-bottom: 0px !important;
    }

    .brand-design-arrow {
        margin-left: 0;
    }

    .arrow-left {
        margin-right: 10px;
    }
}

@media (max-width:767px) {
    .brand-design-container {
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media (max-width:575px) {


    .brand-design-menu-wrap {
        font-size: 14px;
    }

    .carousel-indicators .active,
    .carousel-indicators li {
        border-radius: 0;
    }
}

@media (max-width:425px) {
    .brand-design-col-one {
        padding-left: 15px;
        margin-top: 100px;
    }

    .brand-design-wrap,
    .brand-design-carousel-image {
        height: 100%;
    }

    .brand-design-description {
        padding-right: 0;
    }

    .brand-design-menu-wrap {
        padding: 30px 0 30px 30px;
    }

    .web-brand-logo,
    .web-brand-name {
        display: none;
    }
}

@media (min-width:426px) {

    .mobile-brand-design-logo,
    .mobile-brand-design-name {
        display: none;
    }

    .mobile-brand-logo-container {
        display: none !important;
    }

    .mobile-project-container {
        display: none;
    }

}

@media (max-width:320px) {
    .brand-design-menu-wrap {
        padding-left: 0;
    }
}