.menu-bar {
    display: flex;
    justify-content: space-between;
    z-index: 50;
}

.navFixed,
.avabar-wrapper {
    top: 0;
    position: absolute;
}

.navbar {
    width: 100%;
    padding: 10px;
    position: fixed;
    top: 0;
    transition: top 0.6s;
}

.navbar--hidden {
    top: -50px;
}

.title,
.subtitle {
    font-family: 'timewon';
    letter-spacing: 7.83px;
    font-size: 27px;
    color: #5A5959;
    opacity: 1;
    position: fixed;
    line-height: normal;
    top: 35px;
    left: 43px;
    width: 150px;
    z-index: 51;
}

.subtitle {
    top: 58px;
    left: 43px;
    letter-spacing: 2.43px;
}

.tagline {
    font-family: 'timewon';
    z-index: 30;
    letter-spacing: 4.83px;
    font-size: 14px;
    color: #e2e2e2;
    opacity: 1;
    position: fixed;
    top: 100px;
    left: 43px;
    line-height: normal;

}

.menu-icon {
    position: fixed;
    right: 0;
    top: 58px;
    width: 42px;
    height: 16px;
    right: 43px;
    cursor: pointer;
    z-index: 3;
}

.menu-bar-active {
    position: absolute;
    text-transform: uppercase;
    background-color: #ffffff;
    position: fixed;
    right: 0;
    padding-top: 100px;
    width: 297px;
    list-style: none;
    height: 768px;
    padding-left: 45px;
    letter-spacing: 1.01px;
    box-shadow: 2px 2px 2px 2px #8888889a;
    margin-top: 2px;
    z-index: 50;
    opacity: 1;
}

.hamburger-close {
    width: 30px;
    position: fixed;
    right: 0;
    top: 54px;
    width: 42px;
    height: 16px;
    margin-right: 43px;
    cursor: pointer;
    height: 30px;
}

.about-link,
.contact-link {
    padding-top: 52px;
    padding-bottom: 10px;
    color: #4D4E4E;
    font-size: 14px;
    letter-spacing: 1.01px;
    font-family: 'roboto-light';
}

.web-logo {
    width: 154px;
    height: 65px;
    position: fixed;
    top: 35px;
    z-index: 500;
    left: 43px;
}

.project-link,
.follow-link {
    margin-bottom: 65px;
    color: #4D4E4E;
    font-size: 14px;
    letter-spacing: 1.01px;
    font-family: 'roboto-light';
}

.brand-link {
    font-family: 'roboto';
    color: #A6C37F !important;
}

.main-link {
    padding-bottom: 18px;
    color: #4D4E4E;
    font-family: 'roboto';
    font-size: 14px;
}

.about-link {
    color: #4D4E4E;
}

.about-link,
.contact-link,
.follow-link,
.project-link a {
    color: #4D4E4E;
}

.main-link a {
    color: #4D4E4E;
}

.main-link a:hover {
    color: #BBBBBB;
}


@media (max-width:1024px) {
    .web-logo {
        top: 20px;
        left: 28px;
    }

    .tagline {
        left: 28px;
        top: 85px;
    }
}

@media (max-width:425px) {
    .menu-bar {
        height: 65px;
        top: 0;
        background: #ffffff;
        position: fixed;
        width: 100%;
    }

    .web-version-logo {
        display: none;
    }

    .menu-icon {
        top: 30px;
        margin-right: 12px;
        right: 10px;
    }

    .mobile-logo {
        height: 50px;
        width: 170px;
        position: fixed;
        top: 7px;
        z-index: 100;
    }



    .hamburger-close {
        margin-right: 10px;
        top: 30px;
        height: 20px;
    }

    .menu-bar-active {
        width: 100%;
        padding-top: 70px;
        height: 100%;
        font-size: 17px;
        margin-top: 0;
        overflow-y: scroll;
    }

    .about-link,
    .contact-link,
    .project-link,
    .follow-link {
        font-size: 17px;
        color: #4D4E4E;
        font-family: 'roboto';
        letter-spacing: 1.22px;
        opacity: 1;
    }

    .menu-bar-active a {
        color: #4D4E4E;
    }

    .main-link {
        font-size: 20px;
        letter-spacing: 1.44px;
        padding-bottom: 29px;
    }
}

@media (min-width:426px) {
    .mobile-version-logo {
        display: none;
    }
}

/* .menu-bar {
    display: flex;
    justify-content: space-between;
    z-index: 50;
    position: fixed;
}

.menu-bar-row {
    display: flex;
    justify-content: space-between;
}

.logo {
    margin-top: 35px;
}

.title,
.subtitle {
    font-family: 'timewon';
    letter-spacing: 7.83px;
    font-size: 27px;
    color: #5A5959;
    opacity: 1;
    line-height: normal;
    width: 150px;
    margin-left: 43px;
}

.subtitle {
    margin-left: 43px;
    letter-spacing: 2.43px;
}

.tagline {
    font-family: 'timewon';
    letter-spacing: 4.83px;
    font-size: 14px;
    color: #5A5959;
    opacity: 1;
    line-height: normal;
    margin-left: 43px;
    position: absolute;
}

.menu-icon {
    width: 42px;
    height: 16px;
    cursor: pointer;
    z-index: 3;
    position: relative;
    margin-left: 1060px;
    margin-top: 15px;
}

.menu-bar-active {
    text-transform: uppercase;
    background-color: #ffffff;
    position: fixed;
    right: 0;
    padding-top: 130px;
    width: 297px;
    list-style: none;
    height: 768px;
    padding-left: 45px;
    letter-spacing: 1.01px;
    box-shadow: 2px 2px 2px 2px #8888889a;
    margin-top: 2px;
    z-index: 50;
    opacity: 1;
}

.hamburger-close {
    width: 30px;
    position: fixed;
    right: 0;
    top: 54px;
    width: 42px;
    height: 16px;
    margin-right: 43px;
    cursor: pointer;
    height: 30px;
} */