.homescreen-about-grid-wrap {
    display: flex;
    padding-right: 50px;
}

.homescreen-about-grid {
    width: 150px;
    height: 150px;
}

.homescreen-about-container {
    display: flex;
    justify-content: center;
}

.mobile-about-grid {
    width: 100px;
    height: 100px;
}

.mobile-about-row {
    display: flex;
}

.mobile-about-text {
    width: 100%;
    letter-spacing: 1.36px;
    padding: 25px;
    color: #444444;
    opacity: 1;
    font-family: 'roboto-light';
    font-size: 16px;
}

.homescreen-about-title {
    font-family: 'gotham-light';
    font-size: 35px;
    width: 404px;
    padding-bottom: 21px;
    line-height: 1.2;
    border-bottom: 1px solid #64646456;
}

.homescreen-about-text {
    font-family: 'roboto-light';
    font-size: 16px;
    letter-spacing: 1.12px;
    line-height: 1.7;
    padding-top: 25px;
}

.mobile-about-container {
    margin: 150px 0px 60px 0px;
}

.mobile-about-hero {
    width: 100%;
}

@media (max-width:425px) {

    .homescreen-about-container,
    .homescreen-project-container {
        display: none;
    }
}